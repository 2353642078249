@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?fb3kz1');
  src:  url('../fonts/icomoon.eot?fb3kz1#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?fb3kz1') format('truetype'),
    url('../fonts/icomoon.woff?fb3kz1') format('woff');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-little_arrow_up:before {
  content: "\e915";
}
.icon-paginator_arrow_left:before {
  content: "\e916";
}
.icon-paginator_arrow_right:before {
  content: "\e917";
}
.icon-basket:before {
  content: "\e900";
}
.icon-check_mark:before {
  content: "\e901";
}
.icon-delete_in_filter:before {
  content: "\e902";
}
.icon-delete_in_table:before {
  content: "\e903";
}
.icon-double_triangle:before {
  content: "\e904";
}
.icon-download_bold:before {
  content: "\e905";
}
.icon-download_thin:before {
  content: "\e906";
}
.icon-edit:before {
  content: "\e907";
}
.icon-folder:before {
  content: "\e908";
}
.icon-fullscreen:before {
  content: "\e909";
}
.icon-hide:before {
  content: "\e90a";
}
.icon-home:before {
  content: "\e90b";
}
.icon-info:before {
  content: "\e90c";
}
.icon-plus:before {
  content: "\e90d";
}
.icon-print:before {
  content: "\e90e";
}
.icon-refresh:before {
  content: "\e90f";
}
.icon-save_in_filter:before {
  content: "\e910";
}
.icon-search_mark:before {
  content: "\e911";
}
.icon-triangle:before {
  content: "\e912";
}
.icon-upload_image:before {
  content: "\e913";
}
.icon-watch_on_site:before {
  content: "\e914";
}

