.button {
  color: #fff;
  background-color: $primary-color;
  border: none;
  padding: 0;
  margin: 0;
  text-transform: uppercase;
  height: $button-height;
  line-height: $button-height;
  font-weight: bold;
  font-size: 15px;
  display: inline-block;
  text-decoration: none;
  font-family: $font;

  &.pad {
    padding: 0 25px;
  }

  &.transparent {
    box-shadow: inset 0 0 0 1px #9594A5;
    color: #9594A5;
    background-color: $t;
    font-weight: 500;
  }
}