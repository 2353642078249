.file-manager {
  width: 800px;
  background-color: #fff;
  padding: 30px 15px;

  .drop-info {
    border-radius: 3px;
    background-color: #f7f7f7;
    border: 2px dashed #777;
    text-align: center;
    height: 70px;
    padding: 20px 20px 0;
    margin-bottom: 15px;

    .progress {
      margin-top: 10px;
    }

    .meter {
      height: 11px;
      border-radius: 3px;
      background-color: #FC585E;
      width: 0;
    }
  }

  .actions-row {
    margin-bottom: 20px;

    .button {
      display: block;
      width: 100%;
      text-align: center;
      border-radius: 50px;
    }

    input {
      width: 100%;
    }

    .create-column {
      input, .button {
        float: left;
        width: 50%;

        &:first-child {
          border-radius: 30px 0 0 30px;
        }

        &:last-child {
          border-radius: 0 30px 30px 0;
        }
      }

      input {
        border-right: none;
        padding-left: 20px;
      }
    }
  }

  table.files {
    width: 100%;

    tr {
      &:nth-child(even) {
        background-color: #f7f7f7;
      }

      td {
        vertical-align: middle;

        &.file-check {
          width: 30px;
        }

        &.file-icon {
          width: 30px;
          text-align: center;
        }

        &.file-actions {
          width: 60px;
          text-align: right;
          font-size: 12px;

          a {
            display: inline-block;
          }
        }

        &:first-child {
          padding-left: 7px;
        }

        &:last-child {
          padding-right: 7px;
        }

        a {
          padding: 7px 3px;
          display: block;
          text-decoration: none;
          color: #000;

          i {
            display: block;
          }
        }

        a.file-link {
          position: relative;

          .image-preview-wrapper {
            box-shadow: 0 0 12px 3px rgba(0,0,0,0.3);
            position: absolute;
            bottom: calc(100% + 14px);
            left: 0;

            $width: 20px;
            $height: 10px;
            $color: #fff;
            background-color: #fff;
            opacity: 0;
            pointer-events: none;
            z-index: 100;

            &:after {
              content: '';
              width: 0;
              height: 0;
              border-left: $width/2 dotted transparent;
              border-right: $width/2 dotted transparent;
              border-top: $height solid $color;
              position: absolute;
              bottom: -10px;
              left: 10px;
            }

            .image-preview {
              display: block;
              padding: 5px;

              .image {
                display: block;
                overflow: hidden;

                img {
                  display: block;
                  max-width: 400px;
                  max-height: 300px;
                }
              }
            }
          }

          &:hover {
            .image-preview-wrapper {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}