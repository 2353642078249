.flash-messages-block {
  position: fixed;
  right: 0;
  top: 0;
  left: $menu-width;
  z-index: 1000;

  .flash-list {
    display: block;

    li {
      display: block;
      height: 60px;
      line-height: 60px;
      padding-left: 80px;
      padding-right: 20px;
      font-size: 18px;
      /* Rectangle 2 Copy 2: */

      a {
        text-decoration: none;
      }

      a, span {
        display: inline-block;
        vertical-align: middle;
      }

      &.error {
        background: #FDEAEA url('../images/flash/error-flash.png') 20px 50% no-repeat;
        background-size: 40px 40px;
        border-bottom: 1px solid #E3B7B7;

        span, i {
          color: #955F5F;
        }
      }

      &.success {
        background: #D5F8AD url('../images/flash/success-flash.png') 20px 50% no-repeat;
        background-size: 40px 40px;
        border-bottom: 1px solid #A6D76E;

        span, i {
          color: #698D3F;
        }
      }
    }
  }
}