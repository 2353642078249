.list-block {
  @include checkbox;

  $inactive-color: #d5d5d5;
  $active-color: #A9A9B3;
  $hi-active-color: #8d8d96;

  .list-wrapper {
    position: relative;

    &:after {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      content: '';
      opacity: 0;
      visibility: hidden;
      background: rgba(243, 243, 243, 0.91) url('../images/loader/default.gif') 50% 50% no-repeat;
      z-index: 100;
      transition: all 0.3s;
    }
  }

  &.loading {
    .list-wrapper {
      &:after {
        opacity: 1;
        visibility: visible;
      }
    }
  }


  .list-top {
    margin-bottom: 30px;

    .top-buttons-block {
      margin-right: 30px;

      .button {
        i {
          margin-left: 4px;
          font-size: 13px;
        }
      }
    }

    .top-search-block {
      input {
        height: $button-height;
        min-width: 300px;
      }
    }
  }

  .list-wrapper {

    .table-wrapper {

    }

    table {
      text-align: left;
      width: 100%;
      $th-height: 40px;

      $width-padding: 15px;
      $height-padding: 10px;

      thead {
        tr.list-head {
          th {
            vertical-align: middle;
            height: $th-height + 2px;

            padding-left: $width-padding;
            padding-right: $width-padding;

            &.full {
              background: #FFFFFF;
              border: 1px solid #DFDFDF;
            }

            &.actions {
              background: $t solid #DFDFDF;
              border: none;
              padding-left: 15px;

              .columns-list-appender {
                position: relative;
                display: inline-block;

                &.list {
                  .popup-block {
                    opacity: 1;
                    visibility: visible;
                  }
                }

                .popup-block {
                  top: 100%;
                  position: absolute;
                  right: 0;
                  transition: all 0.3s;
                  opacity: 0;
                  visibility: hidden;

                  .columns-list {
                    margin-top: 11px;
                    position: relative;
                    background-color: #fff;
                    padding: 10px 0;
                    box-shadow: 0px 5px 15px 0px rgba(189,189,189,0.64);

                    &:after {
                      content: '';
                      position: absolute;
                      right: 16px;
                      top: -10px;
                      @include triangle(20px, 10px, up, #fff);
                    }

                    li {
                      label {
                        display: block;
                        padding: 6px 20px 5px;
                        padding-left: 40px;
                        background-position: 15px 50%;
                      }
                    }
                  }
                }
              }


              a {
                display: block;
                width: 50px;
                height: $th-height;
                line-height: $th-height;
                text-align: center;
                color: $primary-color;
                background-color: #fff;
                box-shadow: 0 0 0 1px #DFDFDF;
                text-decoration: none;

                i {
                  display: inline-block;
                  vertical-align: middle;
                }
              }
            }

            .title {
              text-transform: uppercase;
              font-weight: 500;
              font-size: 13px;
              color: #000000;
              text-decoration: none;
              display: block;

              .text {
                display: inline-block;
                vertical-align: middle;
                margin-right: 7px;
              }

              .order {
                display: inline-block;
                vertical-align: middle;
                font-size: 7px;
                color: $inactive-color;

                i.rotate {
                  transform: rotate(180deg);
                  display: inline-block;
                }

                &.active {
                  color: $hi-active-color;
                }
              }
            }
          }
        }

        tr.delimiter {
          th {
            height: 15px;
          }
        }
      }

      th, td {
        &.checker {
          width: 40px;
          padding-left: 0 !important;
          padding-right: 0 !important;
          text-align: center;
          font-size: 0;
        }

        &.sort {
          width: 40px;
          padding-left: 0 !important;
          padding-right: 0 !important;
          text-align: center;

          a {
            text-decoration: none;
            color: rgb(239,239,239);

            &.not-active {
              cursor: not-allowed;
            }

            &.active {
              cursor: move;
              color: #c6c6c6;
            }
          }
        }

        &.tree {
          width: 40px;
          padding-left: 0 !important;
          padding-right: 0 !important;
          text-align: center;

          a {
            text-decoration: none;
            color: #bdbdbd;
          }

          i {
            font-size: 13px;
            color: #bdbdbd;
          }
        }
      }

      tbody {
        tr {
          &[data-children] {
            cursor: pointer;
          }

          &:first-child:not(.ui-sortable-helper), &:first-child.ui-sortable-helper + tr {
            td {
              border-top: 1px solid #DFDFDF;
            }
          }

          td {
            border-left: 1px solid #DFDFDF;
            border-right: 1px solid #DFDFDF;
            padding: $height-padding $width-padding;
            font-size: 15px;
            color: #000000;
            background-color: #fff;
            vertical-align: middle;

            &.actions {
              width: 130px;
              text-align: center;
              padding-left: 0 !important;
              padding-right: 0 !important;

              a {
                font-size: 15px;
                text-decoration: none;
                display: inline-block;
                margin-right: 5px;
                color: $inactive-color;
                transition: all 0.3s;

                &:last-child {
                  margin-right: 0;
                }

                &:hover {
                  color: $active-color;
                }
              }
            }

            &.updatable {
              padding: 0;

              input, textarea, select {
                border: none;
                padding: $height-padding $width-padding;
                display: block;
                width: 100%;
                font-size: 15px;
                background-color: $t;
              }

              label {
                display: none;
              }

              input[type=checkbox], input[type=radio] {
                display: none;
                padding: 0;
              }

              input[type=checkbox] + label, input[type=radio] + label {
                display: block;
                width: 100%;
                margin: $height-padding $width-padding;
              }

              ul {
                margin-top: 0;
                padding: 8px $width-padding 3px;
                background-color: #ffe9ea;

                li {
                  padding-bottom: 4px;
                  margin-bottom: 0;
                }
              }
            }
          }

          &.empty {
            td {
              padding: 30px;
              text-align: center;
              font-size: 20px;
              font-weight: 300;
              color: #aaa;
            }
          }
          &:hover {
            td {
              background: #F7F7F7;
              transition: background 0.3s;

              &.actions {
                a {
                  color: $active-color;

                  &:hover {
                    color: $hi-active-color;
                  }
                }
              }
            }
          }
        }
      }
    }

    .list-footer {
      $list-footer-height: 60px;
      background-color: #fff;

      border: 1px solid #DFDFDF;

      font-size: 13px;
      color: #000000;

      .list-footer-block {
        height: $list-footer-height;

        &.total {
          margin-right: 36px;
        }

        &.group {
          margin-left: 12px;

          .checker-wrapper, .group-buttons, .dropdown-block {
            display: inline-block;
            vertical-align: middle;
          }

          .group-buttons {
            margin-left: 20px;

            a {
              display: inline-block;
              float: left;
              width: 35px;
              height: 30px;
              text-align: center;
              line-height: 32px;
              background: #FFFFFF;
              border: 1px solid #DFDFDF;
              border-right: none;
              text-decoration: none;
              color: $primary-color;

              &:first-child {
                border-radius: 7px 0 0 7px;
              }

              &:last-child {
                border-radius: 0 7px 7px 0;
                border-right: 1px solid #DFDFDF;
              }
            }
          }

          .dropdown-block {
            margin-left: 20px;
            select, button {
              height: 30px;
              display: inline-block;
              vertical-align: middle;
              float: left;
            }

            select {
              border-right: none;
              font-size: 13px;
            }

            button {
              line-height: 34px;
              font-size: 10px;
              padding: 0 12px;
            }
          }
        }
      }
    }

    .pagination-wrapper {
      padding-top: 18px;

      .pagination-list {
        display: inline-block;
        padding-top: 4px;

        li {
          display: inline-block;
          color: #000;
          font-size: 16px;
          height: 20px;
          line-height: 20px;

          a {
            height: 20px;
            text-decoration: none;
            color: #000;
            display: inline-block;
            vertical-align: middle;
            padding: 0 10px;
          }

          &.active, &.prev, &.next {
            &, a {
              color: $primary-color;
            }
          }

          &:first-child {
            a {
              padding-left: 0;
            }
          }

          &:last-child {
            a {
              padding-right: 0;
            }
          }
        }
      }

      .select-block {
        select {
          height: 30px;
          font-size: 15px;
          padding-left: 10px;
        }
      }
    }
  }
}