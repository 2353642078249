.files-input {
  .files-drop {
    border-radius: 3px;
    background-color: #f7f7f7;
    border: 2px dashed #777;
    text-align: center;
    height: 90px;
    padding: 30px 20px 0;

    .top-info {
      color: #777;
      font-size: 15px;

      span {
        display: inline-block;
        vertical-align: middle;
      }

      .as-button {
        color: #000000;
        border: 1px solid #a9a8a9;
        background: #fff;
        padding: 2px 6px;
        border-radius: 5px;
        font-size: 12px;
        margin-left: 10px;
      }
    }

    .bottom-info {
      color: #777;
      font-size: 12px;
      font-style: italic;
      margin-top: 5px;
    }

    .progress {
      margin-top: 10px;
    }

    .meter {
      height: 11px;
      border-radius: 3px;
      background-color: #FC585E;
      width: 0;
    }
  }

  .files-content {
    padding-top: 20px;

    .files-list {
      li {
        margin-bottom: 8px;

        &:last-child {
          margin-bottom: 0;
        }

        .name {
          font-style: italic;
          display: inline-block;
          vertical-align: middle;
          font-size: 12px;
          color: #353535;
        }

        .remove-link {
          display: inline-block;
          vertical-align: middle;
          margin-left: 18px;
          cursor: pointer;

          .remove, .text {
            display: inline-block;
            vertical-align: middle;
          }

          .text {
            color: #295387;
            font-size: 12px;
            font-style: italic;
            text-decoration: underline;
          }
        }
      }

      &.images {
        li {
          position: relative;
          margin: 0;

          &.highlight {
            &:after {
              content: '';
              position: absolute;
              top: 0;
              right: 15px;
              bottom: 30px;
              left: 15px;
              border: 2px dashed #dadada;
            }
          }
        }

        .item-wrapper {
          position: relative;
          text-align: center;

          .remove-link, .edit-link {
            position: absolute;
            margin: 0;
            text-align: center;
            height: 22px;
            line-height: 25px;
            width: 22px;
            top: 10px;
            right: 10px;
            border-radius: 20px;
            background-color: #fff;
            color: #000;
            font-size: 10px;
            text-decoration: none;
            box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2);
          }

          .edit-link {
            left: 10px;
            right: auto;
          }
        }
      }
    }
  }
}