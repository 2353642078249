.main-block .form-page {
  padding: 0 $main-block-padding-width 110px;

  .actions-panel {
    position: fixed;
    left: $menu-width;
    right: 0;
    bottom: 0;
    background: #FFFFFF;
    box-shadow: 0px -4px 4px -1px rgba(189, 189, 189, 0.4);
    padding: 20px 30px;

    .buttons {
      float: left;

      button {
        margin-right: 12px;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .links {
      float: right;
    }
  }

  fieldset {
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
    }

    .fields {
      .form-field {
        input[type=password], input[type=text], select, textarea {
          width: 770px;
        }

        textarea {
          min-height: 110px;
        }

        input[type=file] {
          margin-bottom: 10px;
        }

        .clear-file {
          display: block;
        }
      }
    }
  }

  .related-admins {
    .related-admin {
      margin-bottom: 30px;

      &:last-child {
        margin-bottom: 0;
      }

      h2.title {
        text-transform: uppercase;
        margin-bottom: 20px;
      }

      .unsaved-error {

      }
    }
  }

  .bound-admins {
    & + .related-admins {
      margin-top: 30px;
    }
  }
}

.modal-container.related {
  .modal-content {
    background-color: #fff;

    h1 {
      padding: 20px 30px;
    }

    .form-page {
      width: 1000px;

      fieldset {
        border-left: 0;
        border-right: 0;
      }

      .actions-panel {
        padding: 20px 30px;

        &:before {
          content: "";
          display: table;
        }

        &:after {
          content: "";
          display: table;
          clear: both;
        }

        .buttons {
          float: left;
        }

        .links {
          float: right;
        }
      }

      .related-admins {
        padding: 30px 30px 0;

        .related-admin {
          margin-bottom: 30px;

          &:last-child {
            margin-bottom: 0;
          }

          h2.title {
            text-transform: uppercase;
            margin-bottom: 20px;
          }

          .unsaved-error {

          }
        }
      }
    }

    .admin-form-success {
      text-align: center;
      max-width: 800px;
      min-width: 400px;

      h1 {
        padding: 30px 40px;
      }
    }
  }
}

.form-page {
  $form-padding: 30px;

  fieldset {
    background: #FFFFFF;
    border: 1px solid #DFDFDF;

    .fieldset-title {
      font-weight: 500;
      font-size: 22px;
      color: #000000;
      border-bottom: 1px solid #DFDFDF;
      padding: 25px $form-padding;
    }

    .fields {
      padding: 25px $form-padding;

      .form-field {
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0;
        }

        label {
          font-size: 13px;
          color: #616171;
          display: block;
          margin-bottom: 10px;

          &.required {
            &:after {
              content: '*';
              color: #FB585E;
              padding-left: 3px;
            }
          }
        }

        $inputs: 'input[type=text], input[type=email], input[type=password]';

        #{$inputs}, textarea {
          width: 100%;
        }

        .hint {
          background: #F5F5F5;
          padding: 12px;
          padding-left: 40px;
          font-size: 12px;
          color: #828282;
          position: relative;

          &:before {
            content: '?';
            position: absolute;
            font-weight: bold;
            font-size: 15px;
            color: #8C8C8C;
            top: 10px;
            left: 20px;
          }
        }

        @include checkbox;
      }
    }
  }

  .actions-panel {
    .links {
      a {
        display: inline-block;
        height: $button-height;
        line-height: $button-height;
        text-decoration: none;
        margin-right: 12px;

        font-weight: 500;
        font-size: 15px;
        color: #9594A5;
        text-transform: uppercase;

        &:last-child {
          margin-right: 0;
        }

        i {
          color: rgba(104, 132, 157, 0.4);
          line-height: 40px;
          margin-right: 7px;
        }

        i, span {
          display: inline-block;
          vertical-align: middle;
        }
      }
    }
  }
}