.round {
  border-radius: 1000px;
}

.radius {
  border-radius: 5px;
}

.hide {
  display: none;
}