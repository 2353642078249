$body-font-size: 14px !default;

.v-align {
  font-size: 0;
  &:before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    height: 100%;
  }
  &>* {
    font-size: $body-font-size;
    display: inline-block;
    width: 100%;
    vertical-align: middle;
  }
}

.clearfix {
  @include clearfix();
}

.right {
  float: right;
}

.left {
  float: left;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.upper {
  text-transform: uppercase;
}