ul.errors {
  margin-top: 8px;
  font-size: 12px;
  color: $alert-color;

  li {
    margin-bottom: 5px;
  }
}

$input: "input[type=text], input[type=search], input[type=email], input[type=password]";
#{$input}, textarea {
  background: #FFFFFF;
  border: 1px solid #DFDFDF;
  padding: 0 10px;
  height: $button-height;
  font-size: 14px;
  font-family: $font;

  &::placeholder {
    font-size: 14px;
    color: #797979;
  }
}

textarea {
  padding-top: 10px;
  padding-bottom: 10px;
}

select {
  background: #FFFFFF;
  border: 1px solid #DFDFDF;
  border-radius: 0;
  -moz-appearance: none;
  -webkit-appearance: none;
  font-size: 14px;
  color: #000000;
  height: $button-height;
  padding: 0 10px;
  padding-right: 26px;
  background-image: url('../images/forms/select-bg.png');
  background-repeat: no-repeat;
  background-size: (18px/2) (14px/2);
  background-position: right 10px center;
  font-family: $font;


  &[multiple] {
    height: 150px;
    background-image: none;
    padding-top: 10px;
    padding-bottom: 10px;
    line-height: 1.8;

    option {
      padding: 5px;
    }
  }
}

.current-image {
  margin: 0 0 10px;
  max-width: 300px;
  display: block;
}


@mixin checkbox() {
  input[type=checkbox] {
    display: none;
  }

  input[type=checkbox] + label {
    display: inline-block;
    padding-left: 25px;
    padding-top: 1px;
    min-height: 15px;
    background: $t url('../images/forms/checkbox-unchecked.png') 0 0 no-repeat;
    background-size: 15px 15px;
    font-size: 14px;
  }

  input[type=checkbox]:checked + label {
    background-image: url('../images/forms/checkbox-checked.png');
  }

  input[type=checkbox] + label.alone {
    padding: 0;
    width: 15px;
  }
}