.confirmation-modal {
  background-color: #fff;
  padding: 40px 80px;
  text-align: center;
  max-width: 600px;

  .modal-title {
    font-size: 22px;
    color: #000000;
    line-height: 28px;
    margin-bottom: 20px;
    font-weight: normal;
  }

  .confirm-modal-footer {
    margin-top: 50px;

    .btn {
      width: 140px;
      text-align: center;
      text-transform: uppercase;
      margin: 0 10px;

      &.cancel {
        box-shadow: inset 0 0 0 1px #9594A5;
        color: #9594A5;
        background-color: #fff;
      }
    }
  }
}